import React from "react";
import Layout from "../layout/Layout";
import TextField from "../components/form/TextField";

const ResetPassword = () => {
  return (
    <Layout>
      <div className="page-wrapper">
        <div className="page-heading-section text-center page-block">
          <h1 className="page-title">Reset Password</h1>
        </div>
      </div>

      <div className="container">
        <p className="text-center">
          Fill in your email below to request a new password. An email will be
          sent to the address below containing a link to verify your email
          address.
        </p>

        <form className="form forgot-password-form form-mid">
          <label className="form-label" htmlFor="email">
            Email Address
          </label>
          <div className="form-oneline">
            <TextField id="email" name="email" />
            <button type="submit" class="button">
              Reset Password
            </button>
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default ResetPassword;
